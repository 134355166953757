import { Button } from '@/components/ui/button/button';
import { useNangoConnectionPost } from '@/hooks/mutations/useNango';
import { useNangoSession } from '@/hooks/queries/useNango';
import Nango from '@nangohq/frontend';
import { getProviderAssets } from 'lib/3p';
import type { IntegrationId } from 'lib/integration';
import { NangoOAuthExtraParams } from 'lib/integration';

export const NangoConnect = ({ provider }: { provider: IntegrationId }) => {
    const { data } = useNangoSession(provider);
    const { mutate: postConnection } = useNangoConnectionPost();

    const doConnect = async () => {
        const nango = new Nango({
            connectSessionToken: data?.session.data?.token ?? '',
        });
        const authorization_params = NangoOAuthExtraParams[provider] ?? {};

        const result = await nango.auth(provider, { authorization_params });

        postConnection({
            connectionId: result.connectionId,
            integrationId: provider,
        });
    };
    const { name } = getProviderAssets(provider);
    return <Button onClick={doConnect}>Connect {name}</Button>;
};
