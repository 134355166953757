import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import {
    type HelpDeskConfigUpdateSchema,
    type TicketProvisioningStatusSetting,
    TicketProvisioningStatusSettingKey,
} from 'lib/models/help_desk';
import type { CnslTicketTypeType } from 'lib/prisma/enums';
import type { HelpDeskConfig } from 'lib/prisma/types';

export const useHelpDeskConfigUpsert = () => {
    const queryClient = useQueryClient();
    return useMutation<HelpDeskConfig, DefaultError, HelpDeskConfigUpdateSchema>({
        url: `/api/v1/help-desk/configs`,
        method: 'PUT',
        successToast: {
            message: 'Helpdesk config updated',
        },
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: ['help-desk-configs'] });
            queryClient.refetchQueries({ queryKey: [TicketProvisioningStatusSettingKey] });
        },
    });
};

export const useSetSeamlessHandoffSettings = () => {
    const queryClient = useQueryClient();
    return useMutation<boolean, DefaultError, { enabled: boolean }>({
        url: `/api/v1/help-desk/seamless-handoff-settings`,
        method: 'PUT',
        successToast: {
            message: 'Helpdesk config updated',
        },
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: ['seamless-handoff-settings'] });
        },
    });
};

export const useSetTicketProvisioningStatus = (type: CnslTicketTypeType) => {
    const queryClient = useQueryClient();
    return useMutation<TicketProvisioningStatusSetting, DefaultError, TicketProvisioningStatusSetting>({
        url: `/api/v1/help-desk/ticket-provisioning-status/${type}`,
        method: 'PUT',
        successToast: {
            message: 'Tracked status updated',
        },
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [TicketProvisioningStatusSettingKey] });
        },
    });
};
