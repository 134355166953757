import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';
import { IntegrationId } from 'lib/integration';
import type { RequestProviderFieldRes, SchemaRequestProviderFieldReq } from 'lib/models/settings/requests';
import qs from 'qs';
import type * as v from 'valibot';

import { useQuery } from '@/lib/hooks/useQuery';
import type { APIResponse } from 'lib/models/error';
import { TicketProvisioningStatusSettingKey } from 'lib/models/help_desk';
import type { Nullish } from 'lib/models/primatives';
import { ONBOARDING_SUBSCRIBE_MUTATION_KEY } from '../useSubscribeOnboardingFlow';

export const useSettingRequest = (provider: IntegrationId, namespace?: string[], ticketType?: Nullish<string>) => {
    /* Need to always include default namespace for fs */
    if (provider === IntegrationId.Freshservice && namespace && !namespace.includes('1')) {
        namespace!.push('1');
    }
    return useQuery<APIResponse<RequestProviderFieldRes>>({
        url: `/api/v1/settings/requests/${provider}${namespace ? `?${qs.stringify({ namespace, ticketType })}` : ''}`,
    });
};

export const useSettingRequestUpdate = (
    provider: IntegrationId,
    { onSuccess = () => {} }: { onSuccess?: () => void },
) => {
    const queryClient = useQueryClient();
    return useMutation<RequestProviderFieldRes, DefaultError, v.InferInput<typeof SchemaRequestProviderFieldReq>>({
        url: `/api/v1/settings/requests/${provider}`,
        method: 'PUT',
        mutationKey: [ONBOARDING_SUBSCRIBE_MUTATION_KEY],
        successToast: {
            message: 'Help desk config updated',
        },
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: ['help-desk-fields'] });
            await queryClient.refetchQueries({ queryKey: [TicketProvisioningStatusSettingKey] });

            // TODO refetch or use response to update the cache?
            onSuccess();
        },
    });
};
