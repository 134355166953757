import { ChevronDown, CircleDashed, UserRoundSearch } from 'lucide-react';
import qs from 'qs';
import { useMemo } from 'react';
import type { MultiValue } from 'react-select';

import { ResLink } from '@/components/ResLink';
import { Badge } from '@/components/ui/badge/badge';
import { Button, type ButtonProps } from '@/components/ui/button/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { GroupResLinkOption } from '@/components/ui/select/UserResLinkComponents';
import { useGroups } from '@/hooks/queries/useGroups';
import { useUsers } from '@/hooks/queries/useUsers';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { CnslGroupIcon } from '@/lib/iconConstants';
import { cn } from '@lib/styling';
import { prettyReviewStrategy } from 'lib/models/app_policy';
import type { CnslGroupList } from 'lib/models/group';
import type { PaginatedListResponse } from 'lib/models/pagination';
import type { Prisma } from 'lib/prisma/client';
import { AppPolicyReviewStrategy, type AppPolicyReviewStrategyType } from 'lib/prisma/enums';

import { SelectAsync } from './ui/select';

interface StrategyConfig {
    strategy: AppPolicyReviewStrategyType;
    label: string;
    Icon: React.ComponentType;
    top: boolean;
}

type CnslUserList = PaginatedListResponse<
    Prisma.cnsl_userGetPayload<{
        include: {
            manager: true;
        };
    }>
>;

const allStrategyOpts: StrategyConfig[] = [
    {
        strategy: AppPolicyReviewStrategy.APPROVERS_MANAGER,
        label: prettyReviewStrategy(AppPolicyReviewStrategy.APPROVERS_MANAGER),
        Icon: UserRoundSearch,
        top: true,
    },
    {
        strategy: AppPolicyReviewStrategy.APP_OWNERS,
        label: prettyReviewStrategy(AppPolicyReviewStrategy.APP_OWNERS),
        Icon: UserRoundSearch,
        top: true,
    },
    {
        strategy: AppPolicyReviewStrategy.REQUESTERS_MANAGER,
        label: prettyReviewStrategy(AppPolicyReviewStrategy.REQUESTERS_MANAGER),
        Icon: UserRoundSearch,
        top: true,
    },
    {
        strategy: AppPolicyReviewStrategy.NO_REVIEW_REQUIRED,
        label: prettyReviewStrategy(AppPolicyReviewStrategy.NO_REVIEW_REQUIRED),
        Icon: CircleDashed,
        top: true,
    },
    {
        strategy: AppPolicyReviewStrategy.EXPLICIT,
        label: 'Users',
        Icon: UserRoundSearch,
        top: false,
    },
    {
        strategy: AppPolicyReviewStrategy.EXPLICIT,
        label: 'Groups',
        Icon: CnslGroupIcon,
        top: false,
    },
];

interface ApprovalSelectProps extends ButtonProps {
    strategy: AppPolicyReviewStrategyType;
    userIds: string[];
    groupIds: string[];
    excludedStrategies?: AppPolicyReviewStrategyType[];
    alwaysAllowExplicit?: boolean;
    onStrategy: (strategy: AppPolicyReviewStrategyType) => void;
    onUsers: (userIds: string[]) => void;
    onGroups: (groupIds: string[]) => void;
}

export const SelectedContainer = () => {
    return null;
};

export function ApprovalSelect({
    strategy,
    userIds,
    groupIds,
    onStrategy,
    onUsers,
    onGroups,
    excludedStrategies,
    alwaysAllowExplicit,
    ...props
}: ApprovalSelectProps) {
    const { data: users } = useUsers({ userIds: userIds, enabled: userIds.length > 0 });
    const { data: groups } = useGroups(groupIds);

    interface ResourceSelectOption {
        value: string;
        label: string;
        logo?: string;
    }

    const selectedUsers =
        users?.items.map(u => ({
            value: u.id,
            label: u.displayName,
            logo: u.avatar ?? undefined,
        })) ?? [];

    const selectedGroups =
        groups?.items.map(g => ({
            value: g.id,
            label: g.name ?? '',
            logo: g.logo ?? undefined,
        })) ?? [];

    const explicitSelections = selectedUsers
        .map(user => ({
            type: 'users',
            id: user.value,
            displayName: user.label,
            avatar: user.logo,
        }))
        .concat(
            selectedGroups.map(group => ({
                type: 'groups',
                id: group.value,
                displayName: group.label,
                avatar: group.logo,
            })),
        );

    const explicitSelectionLabel = useMemo(() => {
        const groupCount = selectedGroups.length;
        const userCount = selectedUsers.length;
        if (groupCount > 0 && userCount === 0) {
            return `${groupCount} Group${groupCount > 1 ? 's' : ''}`;
        }
        if (userCount > 0 && groupCount === 0) {
            return `${userCount} User${userCount > 1 ? 's' : ''}`;
        }
        return `${userCount} user${userCount !== 1 ? 's' : ''} and ${groupCount} group${groupCount !== 1 ? 's' : ''}`;
    }, [selectedGroups, selectedUsers]);

    const onUserSelect = (vals: MultiValue<ResourceSelectOption>) => {
        onUsers(vals.map(v => v.value));
        onStrategy(AppPolicyReviewStrategy.EXPLICIT);
    };

    const onGroupSelect = (vals: MultiValue<ResourceSelectOption>) => {
        onGroups(vals.map(v => v.value));
        onStrategy(AppPolicyReviewStrategy.EXPLICIT);
    };
    const excluded = excludedStrategies ?? [];
    const strategyOpts = allStrategyOpts.filter(opt => !excluded.includes(opt.strategy));

    const currentStrat = strategyOpts.find(opt => opt.strategy === strategy) ?? strategyOpts[0];

    const baseLabel = useMemo(() => {
        if (alwaysAllowExplicit) {
            const parts: string[] = [];
            if (strategy === 'APP_OWNERS') {
                parts.push('App owner');
            } else {
                parts.push(currentStrat.label);
            }
            if (selectedGroups.length + selectedUsers.length > 0) {
                parts.push(explicitSelectionLabel);
            }
            return parts.join(' and ');
        }
        return currentStrat.label;
    }, [alwaysAllowExplicit, explicitSelectionLabel, selectedGroups, selectedUsers, strategy, currentStrat.label]);

    const topLevels = strategyOpts.filter(opt => opt.top);
    const userOpt = strategyOpts.find(opt => opt.label === 'Users');
    const groupOpt = strategyOpts.find(opt => opt.label === 'Groups');

    const displayedSelections = explicitSelections.slice(0, 3);
    const leftoverSelectionCount = explicitSelections.length - displayedSelections.length;

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild {...props}>
                <Button className="flex items-center justify-between">
                    {currentStrat.strategy === AppPolicyReviewStrategy.EXPLICIT ? (
                        <div className="flex items-center">
                            {displayedSelections.map((selection, idx) => (
                                <div key={selection.id} className={idx > 0 ? '-ml-sm' : ''}>
                                    <ResLink
                                        entity={selection.type as 'apps' | 'groups'}
                                        src={selection.avatar}
                                        label={selection.displayName}
                                        avatarOnly
                                        size="sm"
                                    />
                                </div>
                            ))}
                            {leftoverSelectionCount > 0 && (
                                <div className="-ml-sm size-4 text-[7px] flex items-center justify-center rounded-full text-white bg-bg-grey-secondary z-10">
                                    <span>+</span>
                                    <span>{leftoverSelectionCount}</span>
                                </div>
                            )}
                            <p className={cn(displayedSelections.length > 0 ? 'ml-sm' : '')}>
                                {explicitSelectionLabel}
                            </p>
                        </div>
                    ) : (
                        <div className="gap-x-sm flex items-center">
                            <currentStrat.Icon />
                            {baseLabel}
                        </div>
                    )}
                    <ChevronDown />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent side="bottom" align="start" className="w-[275px]">
                {topLevels.map(item => (
                    <DropdownMenuItem
                        key={item.strategy}
                        onSelect={() => onStrategy(item.strategy)}
                        className="gap-x-sm flex items-center"
                    >
                        <item.Icon />
                        {item.label}
                    </DropdownMenuItem>
                ))}
                <DropdownMenuSeparator />

                {userOpt && (
                    <DropdownMenuSub>
                        <DropdownMenuSubTrigger className="flex items-center">
                            <div className="flex w-full items-center justify-between">
                                <div className="gap-x-sm flex items-center">
                                    <userOpt.Icon />
                                    {userOpt.label}
                                </div>
                                {selectedUsers.length > 0 && (
                                    <Badge className="mr-sm">{selectedUsers.length} selected</Badge>
                                )}
                            </div>
                        </DropdownMenuSubTrigger>
                        <DropdownMenuSubContent className="w-[275px]" collisionPadding={5}>
                            <SelectAsync
                                isMulti
                                menuIsOpen
                                placeholder="Search Users"
                                trigger="menuSub"
                                defaultValue={selectedUsers}
                                controlShouldRenderValue={false}
                                components={{
                                    Option: GroupResLinkOption,
                                }}
                                loadOptions={async (search: string, _options, additional) => {
                                    const res = await baseFetch<CnslUserList>(
                                        `/api/v1/users/search?${qs.stringify({
                                            search,
                                            ids: userIds,
                                            cursor: additional?.cursor,
                                        })}`,
                                        { headers: getHeaders() },
                                    );

                                    return {
                                        options: res.items.map(value => ({
                                            label: value.displayName,
                                            value: value.id,
                                            logo: value.avatar ?? undefined,
                                        })),
                                        hasMore: !!res.pagination.nextCursor,
                                        additional: {
                                            cursor: res.pagination.nextCursor,
                                        },
                                    };
                                }}
                                onChange={onUserSelect}
                                loadingMessage={() => null}
                            />
                        </DropdownMenuSubContent>
                    </DropdownMenuSub>
                )}

                {groupOpt && (
                    <DropdownMenuSub>
                        <DropdownMenuSubTrigger className="flex items-center">
                            <div className="flex w-full items-center justify-between">
                                <div className="gap-x-sm flex items-center">
                                    <groupOpt.Icon />
                                    {groupOpt.label}
                                </div>
                                {selectedGroups.length > 0 && (
                                    <Badge className="mr-sm">{selectedGroups.length} selected</Badge>
                                )}
                            </div>
                        </DropdownMenuSubTrigger>
                        <DropdownMenuSubContent className="w-[275px]" collisionPadding={5}>
                            <SelectAsync
                                isMulti
                                placeholder="Search Groups"
                                defaultValue={selectedGroups}
                                trigger="menuSub"
                                defaultMenuIsOpen
                                loadingMessage={() => null}
                                controlShouldRenderValue={false}
                                components={{
                                    Option: GroupResLinkOption,
                                }}
                                loadOptions={async (search: string, _options, additional) => {
                                    const res = await baseFetch<PaginatedListResponse<CnslGroupList>>(
                                        `/api/v1/groups/search?${qs.stringify({
                                            search,
                                            cursor: additional?.cursor,
                                            ids: groupIds,
                                        })}`,
                                        { headers: getHeaders() },
                                    );

                                    return {
                                        options: res.items.map(value => ({
                                            label: value.name ?? '',
                                            value: value.id ?? '',
                                            logo: value.logo ?? undefined,
                                        })),
                                        hasMore: res.pagination.remaining > 0,
                                        additional: {
                                            cursor: res.pagination.nextCursor,
                                        },
                                    };
                                }}
                                onChange={onGroupSelect}
                            />
                        </DropdownMenuSubContent>
                    </DropdownMenuSub>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
