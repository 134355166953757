import { useQuery } from '@/lib/hooks/useQuery';
import { IntegrationId } from 'lib/integration';
import type { IssueType, Resolution } from 'lib/jira/types';
import { isApiError } from 'lib/models/error';
import { type NamespaceOptions, TicketProvisioningStatusSettingKey } from 'lib/models/help_desk';
import { type CnslTicketTypeType, TicketProvider } from 'lib/prisma/enums';
import type { HelpDeskConfig, HelpDeskFieldConfig } from 'lib/prisma/types';
import { useMemo } from 'react';
import { useIntegrationSettingsStatus } from './integrations/useIntegrationSettingsStatus';
import { useSettingRequest } from './useSettingRequest';

export const useHelpDeskConfigs = () => {
    return useQuery<{ configs: HelpDeskConfig[] }>({
        url: `/api/v1/help-desk/configs`,
        queryKey: ['help-desk-configs'],
    });
};

export const useHelpDeskFields = (provider: IntegrationId, namespace: string, ticketType: CnslTicketTypeType) => {
    return useQuery<{ fields: HelpDeskFieldConfig[] }>({
        url: `/api/v1/help-desk/fields?provider=${provider}&namespace=${namespace}&cnslTicketType=${ticketType}`,
        queryKey: ['help-desk-fields', provider, namespace, ticketType],
    });
};

export const useSeamlessHandoffSettings = () => {
    return useQuery<{ enabled: boolean }>({
        url: `/api/v1/help-desk/seamless-handoff-settings`,
        queryKey: ['seamless-handoff-settings'],
    });
};

export const useNamespaceOptions = () => {
    return useQuery<NamespaceOptions>({
        url: '/api/v1/help-desk/namespace-options',
    });
};

export const useHelpDeskProvidersStatus = () => {
    const helpDeskProviders = Object.values(TicketProvider);

    const { data } = useIntegrationSettingsStatus();
    const statusData = data?.status ?? {};

    const enabledHelpdesks = useMemo(() => {
        return helpDeskProviders.map(provider => ({
            provider,
            enabled: statusData[provider] ?? false,
        }));
    }, [statusData, helpDeskProviders]);

    return enabledHelpdesks;
};

export const useJiraIssueTypes = (namespace: string): IssueType[] => {
    const { data } = useSettingRequest(IntegrationId.Jira, [namespace]);
    if (isApiError(data)) {
        return [];
    }
    return data?.ticketTypes ?? [];
};

export const useTicketProvisioning = (type: CnslTicketTypeType) => {
    return useQuery<{
        options: { label: string; value: string }[];
        selected: string | null;
        jiraResolution: string | null;
        resolutions?: Resolution[];
    }>({
        url: `/api/v1/help-desk/ticket-provisioning-options/${type}`,
        queryKey: [TicketProvisioningStatusSettingKey, type],
    });
};
