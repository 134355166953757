import type { IntegrationId } from 'lib/integration';

import { useQuery } from '@/lib/hooks/useQuery';

export const useNangoSession = (integrationId: IntegrationId) => {
    return useQuery<{
        session: {
            data: {
                token: string;
            };
        };
    }>({
        url: `/api/v1/nango/session?integrationId=${integrationId}`,
    });
};
