import { ApprovalSelect } from '@/components/ApproverSelect';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/form';
import { Select } from '@/components/ui/select';
import { useDefaultPolicySettingsPut } from '@/hooks/mutations/policies/userDefaultPolicySettingsPut';
import { useDefaultPolicyConfig } from '@/hooks/queries/usePolicies';
import { cn } from '@/lib/styling';
import { useApprovalSettings } from '@/stores/useApprovalSettings';
import { makeDurationOptions } from 'lib/models/app_policy';
import { nudgeCounts, nudgeIntervals } from 'lib/models/app_policy';
import { AppPolicyDuration, type AppPolicyDurationType, AppPolicyReviewStrategy } from 'lib/prisma/enums';
import { BadgeCheck, Clock } from 'lucide-react';
import type React from 'react';
import { useEffect, useState } from 'react';

export const SectionHeader = ({
    heading,
    description,
    icon,
    iconBg,
}: { heading: string; description: string; icon: React.ReactNode; iconBg: string }) => {
    return (
        <div className="flex items-center gap-lg border-b border-grey p-lg">
            <div className={cn('p-2 rounded-full', iconBg)}>{icon}</div>
            <div>
                <h2>{heading}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
};

export const LabelDescription = ({
    label,
    description,
    forId,
    children,
}: { label: string; description: string; forId: string; children?: React.ReactNode }) => {
    return (
        <div className="flex flex-col">
            <Label htmlFor={forId} className="text-grey font-semibold">
                {label}
            </Label>
            <p className="mb-sm">{description}</p>
            {children}
        </div>
    );
};

const PendingApprovalSettings = () => {
    const store = useApprovalSettings();

    const { mutate: updateSettings } = useDefaultPolicySettingsPut();

    useEffect(() => {
        if (store.isDirty() && store.settings) {
            updateSettings(store.settings);
            store.init(store.settings);
        }
    }, [store.settings, updateSettings, store.isDirty, store.init]);

    return (
        <>
            <SectionHeader
                heading="Pending approvals"
                description="How Console will handle reviewers who are OOO or fail to respond in a timely manner"
                icon={<Clock />}
                iconBg="bg-bg-yellow-secondary"
            />
            <form className="p-lg flex flex-col gap-lg">
                <LabelDescription
                    label="Nudge reviewer every..."
                    description="How often should we nudge someone about pending approvals that require their review?"
                    forId="nudge-interval"
                >
                    <Select
                        className="lg:w-2/5 max-w-sm"
                        id="nudge-interval"
                        options={nudgeIntervals}
                        value={nudgeIntervals.find(
                            i => i.value === store.currentSettings?.pendingApprovalNudgeInterval,
                        )}
                        onChange={v => store.setSettings({ pendingApprovalNudgeInterval: v?.value })}
                    />
                </LabelDescription>
                <LabelDescription
                    label="Escalate after..."
                    description="How many times should we nudge someone before we escalate the request?"
                    forId="escalate-count"
                >
                    <Select
                        className="lg:w-2/5 max-w-sm"
                        id="escalate-count"
                        options={nudgeCounts}
                        value={nudgeCounts.find(v => v.value === store.currentSettings?.pendingApprovalNudgeCount)}
                        onChange={v => store.setSettings({ pendingApprovalNudgeCount: v?.value })}
                    />
                </LabelDescription>
                <LabelDescription
                    label="Escalate to..."
                    description="Who should we escalate requests to by default when the request has not been approved in time?"
                    forId="escalate-to"
                >
                    <ApprovalSelect
                        className="lg:w-2/5 max-w-sm"
                        id="escalate-to"
                        strategy={
                            store.currentSettings?.pendingApprovalEscalationStrategy ??
                            AppPolicyReviewStrategy.APP_OWNERS
                        }
                        userIds={store.currentSettings?.pendingApprovalEscalationUserIds ?? []}
                        groupIds={store.currentSettings?.pendingApprovalEscalationGroupIds ?? []}
                        onStrategy={strategy => store.setSettings({ pendingApprovalEscalationStrategy: strategy })}
                        onUsers={userIds => store.setSettings({ pendingApprovalEscalationUserIds: userIds })}
                        onGroups={groupIds => store.setSettings({ pendingApprovalEscalationGroupIds: groupIds })}
                        excludedStrategies={[AppPolicyReviewStrategy.APPROVERS_MANAGER]}
                    />
                </LabelDescription>
            </form>
        </>
    );
};

export const Approvals = () => {
    const { data: config } = useDefaultPolicyConfig();
    const store = useApprovalSettings();

    const userOpts = new Set<AppPolicyDurationType>();
    config?.settings?.defaultPolicyDurationOptions?.forEach(d => userOpts.add(d));

    const durations = makeDurationOptions(window.navigator.language);

    const duration = durations.find(d => d.value === store?.settings?.defaultPolicyDuration) ?? durations[0];
    const [userDefinedDurations, setUserDefinedDurations] = useState(new Set(userOpts));
    const userDefinedDurationOptions = durations.filter(d => d.value !== AppPolicyDuration.USER_DEFINED);

    useEffect(() => {
        if (config?.settings) {
            setUserDefinedDurations(new Set(config.settings.defaultPolicyDurationOptions));

            store.init(config.settings);
        }
    }, [config, store.init]);

    const { mutate: updateSettings } = useDefaultPolicySettingsPut();

    useEffect(() => {
        if (store.isDirty() && store.settings) {
            updateSettings(store.settings);
            store.init(store.settings);
        }
    }, [store.settings, updateSettings, store.isDirty, store.init]);

    const handleCheck = (checked: boolean, duration: AppPolicyDurationType) => {
        if (checked) {
            userDefinedDurations.add(duration);
        } else {
            userDefinedDurations.delete(duration);
        }
        setUserDefinedDurations(new Set(userDefinedDurations));
        store.setSettings({ defaultPolicyDurationOptions: Array.from(userDefinedDurations) });
    };

    return (
        <div className="flex flex-col gap-xl">
            <section className="border-grey border rounded-md">
                <SectionHeader
                    heading="Default approval settings"
                    description="Define which settings you would like to appear by default when creating an access policy"
                    icon={<BadgeCheck className="text-white" />}
                    iconBg="bg-bg-green-secondary"
                />
                <form className="p-lg flex flex-col gap-lg">
                    <div className="flex flex-col gap-sm">
                        <Label htmlFor="default-approver" className="text-grey font-semibold">
                            Default approver
                        </Label>
                        <ApprovalSelect
                            className="lg:w-2/5 max-w-sm"
                            id="default-approver"
                            strategy={
                                store.currentSettings?.defaultPolicyReviewStrategy ?? AppPolicyReviewStrategy.APP_OWNERS
                            }
                            userIds={store.currentSettings?.defaultPolicyUserReviewerIds ?? []}
                            groupIds={store.currentSettings?.defaultPolicyGroupReviewerIds ?? []}
                            onStrategy={strategy => store.setSettings({ defaultPolicyReviewStrategy: strategy })}
                            onUsers={userIds => store.setSettings({ defaultPolicyUserReviewerIds: userIds })}
                            onGroups={groupIds => store.setSettings({ defaultPolicyGroupReviewerIds: groupIds })}
                            excludedStrategies={[AppPolicyReviewStrategy.APPROVERS_MANAGER]}
                        />
                    </div>
                    <div className="flex flex-col gap-sm">
                        <Label htmlFor="duration-select" className="text-grey font-semibold">
                            Default access length
                        </Label>
                        <Select
                            className="lg:w-2/5 max-w-sm"
                            id="duration-select"
                            options={durations}
                            value={duration}
                            onChange={v => store.setSettings({ defaultPolicyDuration: v?.value })}
                        />
                    </div>
                    {duration.value === AppPolicyDuration.USER_DEFINED && (
                        <div className="flex flex-col gap-sm">
                            <p>Select the default options you want to allow the user to choose from</p>
                            <div className="flex gap-lg flex-wrap">
                                {userDefinedDurationOptions.map(d => (
                                    <div key={d.value} className="flex items-center gap-sm">
                                        <Checkbox
                                            id={d.value}
                                            checked={userDefinedDurations.has(d.value)}
                                            className="data-[state=checked]:bg-bg-blue-secondary"
                                            onCheckedChange={checked => handleCheck(!!checked, d.value)}
                                        />
                                        <Label htmlFor={d.value}>{d.label}</Label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </form>
            </section>
            <section className="border-grey border rounded-md">
                <PendingApprovalSettings />
            </section>
        </div>
    );
};
