import { baseFetch } from '@/lib/baseFetch';
import { AGENTS, ROI_ROOT } from '@/routes/paths';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import { BookCopy, Brain, ChevronRight, Flag, LogOut, MousePointerClick, PanelLeftClose, Settings } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { NavMenu } from '@/components/NavMenu';
import { ResLink } from '@/components/ResLink';
import { Button } from '@/components/ui/button/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import {
    CnslAgentIcon,
    CnslAppIcon,
    CnslGroupIcon,
    CnslInsightsIcon,
    CnslPolicyIcon,
    CnslUserIcon,
} from '@/lib/iconConstants';
import { cn } from '@/lib/styling';
import {
    APPS,
    ARTICLES,
    EVENTS,
    GROUPS,
    INSIGHTS,
    PLAYBOOKS,
    POLICIES,
    REQUESTS,
    SETTINGS,
    SETTINGS_ORGANIZATION_GENERAL,
    USERS,
} from '@/routes/paths';
import { useApp } from '@/stores/useApp';
import { getOrgMembership, useUser as useUserStore } from '@/stores/useUser';
import { FlagKey } from 'lib/flags/keys';
import { OrgOnboardingStatus } from 'lib/prisma/enums';
import { ProgressCard } from '../onboarding/ProgressCard';

const items = [
    // { name: 'Home', pathname: ROOT, Icon: AppWindowMac, matchPathExactly: true },
    { section: 'Overview' },
    { name: 'Requests', pathname: REQUESTS, Icon: Flag },
    { name: 'Insights', pathname: INSIGHTS, Icon: CnslInsightsIcon, disabledForNerfed: true },
    { name: 'Agents', pathname: AGENTS, Icon: CnslAgentIcon, flag: FlagKey.Agents, disabledForNerfed: true },
    { section: 'Train' },
    { name: 'Knowledge Base', pathname: ARTICLES, Icon: Brain, flag: FlagKey.KnowledgeBase },
    { name: 'Access Policies', pathname: POLICIES, Icon: CnslPolicyIcon, disabledForNerfed: true },
    { name: 'Playbooks', pathname: PLAYBOOKS, Icon: BookCopy, disabledForNerfed: true },
    { section: 'Resources' },
    { name: 'Users', pathname: USERS, Icon: CnslUserIcon },
    { name: 'Apps', pathname: APPS, Icon: CnslAppIcon },
    { name: 'Groups', pathname: GROUPS, Icon: CnslGroupIcon, disabledForNerfed: true },
    { name: 'Events', pathname: EVENTS, Icon: MousePointerClick, disabledForNerfed: true },
];

const Nav = () => {
    const { pathname } = useLocation();
    const { setApp, app } = useApp();
    const { user } = useUserStore.getState();
    const cnslUser = user?.cnslUser;
    const navigate = useNavigate();

    const membership = getOrgMembership(user);
    const nerfed = membership?.nerfed;

    const isOnSettings = pathname.includes(SETTINGS);
    const settingsLinkClassName = cn(
        'text-body-subtle hover:text-body-subtle-hover hover:bg-bg-grey-primary-hover group flex size-6 items-center justify-center rounded-full',
        isOnSettings ? 'text-body-subtle-hover bg-bg-grey-primary-hover' : '',
    );
    const settingsIconClassName = cn(
        'size-3.5 transition duration-300 group-hover:rotate-90',
        isOnSettings ? 'rotate-90' : '',
    );

    const handleLogout = async () => {
        try {
            await baseFetch('/auth/logout', {
                method: 'GET',
            });

            navigate('/login');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const isRoiReportEnabled = useFeatureFlag(FlagKey.RoiReport);

    return (
        <div className="pl-md flex h-full w-[240px] shrink-0 flex-col">
            <div className="px-sm py-md flex w-full items-center justify-between gap-lg mb-[2px]">
                <Popover>
                    <PopoverTrigger asChild>
                        <Button mode="borderless" className="hover:bg-steel-600">
                            <ResLink
                                label={cnslUser?.displayName ?? user?.user.username ?? ''}
                                bold
                                src={cnslUser?.avatar}
                            />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent side="bottom" align="start">
                        <div className="p-sm">
                            <Button mode="borderless" className="w-full justify-start" onClick={handleLogout}>
                                <LogOut />
                                Logout
                            </Button>
                        </div>
                    </PopoverContent>
                </Popover>
                <div className="flex items-center gap-[2px]">
                    <Link className={settingsLinkClassName} to={SETTINGS_ORGANIZATION_GENERAL}>
                        <Settings className={settingsIconClassName} />
                    </Link>
                    {app.isNavbarOpen && (
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    mode="borderless"
                                    size="sm"
                                    className="text-body-subtle hover:text-body-subtle-hover hover:bg-bg-grey-primary-hover size-6 rounded-full bg-transparent"
                                    onClick={() => {
                                        setApp({ isNavbarOpen: false });
                                        setApp({ isNavbarHovered: false });
                                    }}
                                >
                                    <PanelLeftClose className="shrink-0 size-3.5" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent side="right">Collapse nav</TooltipContent>
                        </Tooltip>
                    )}
                </div>
            </div>
            {!nerfed && membership?.onboardingStatus !== OrgOnboardingStatus.HIDDEN && <ProgressCard />}
            <NavMenu items={items} />
            {!nerfed && isRoiReportEnabled && (
                <Link
                    to={ROI_ROOT}
                    className="w-full bg-bg transition-all duration-150 ease-out rounded-[6px] p-md shadow-sm hover:shadow flex items-center gap-md hover:scale-98 mb-lg"
                >
                    <img src="/roi-piechart.svg" alt="ROI Report" />
                    <div className="flex flex-col w-full">
                        <p className="font-medium line-clamp-1 w-full">View your ROI report</p>
                        <p className="line-clamp-1 w-full text-body-subtle mt-[-2px] text-xs">
                            See how Console can help
                        </p>
                    </div>
                    <ChevronRight className="shrink-0 size-3.5" />
                </Link>
            )}
            <div className="p-md">
                <div className="h-lg bg-[url('/console-grey.svg')] bg-[length:auto_16px] bg-left-bottom bg-no-repeat" />
            </div>
        </div>
    );
};

export default Nav;
