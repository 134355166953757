import ComboboxPopover from '@/components/ComboboxPopover';
import FlaggedFeature from '@/components/FlaggedFeature';
import { Button } from '@/components/ui/button/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { useSetSeamlessHandoffSettings, useSetTicketProvisioningStatus } from '@/hooks/mutations/useHelpdesk';
import {
    useHelpDeskFields,
    useNamespaceOptions,
    useSeamlessHandoffSettings,
    useTicketProvisioning,
} from '@/hooks/queries/useHelpdesk';
import { useSettingRequest, useSettingRequestUpdate } from '@/hooks/queries/useSettingRequest';
import { cn } from '@/lib/styling';
import { valibotResolver } from '@hookform/resolvers/valibot';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/tooltip';
import { FlagKey } from 'lib/flags';
import type { IntegrationId } from 'lib/integration';
import type { CnslTicketTypeSettings } from 'lib/models/help_desk';
import { isApiError } from 'lib/models/index';
import type { Nullish } from 'lib/models/primatives';
import { SchemaRequestCanBeAutoCreated, SchemaRequestProviderFieldReq } from 'lib/models/settings/requests';
import {
    CnslTicketType,
    HelpDeskFieldVisibility,
    RequestFieldPopulationType,
    RequestFieldType,
    type RequestFieldTypeType,
    TicketProvider,
} from 'lib/prisma/enums';
import type { CnslTicketTypeType, RequestFieldPopulationTypeType, TicketProviderType } from 'lib/prisma/enums';
import type { HelpDeskFieldConfig, RequestProviderField } from 'lib/prisma/types';
import {
    ArrowRightLeft,
    Blocks,
    CircleUserRound,
    Eye,
    EyeOff,
    PenLine,
    Space,
    Sparkles,
    TextCursorInput,
    Ticket,
} from 'lucide-react';
import { type ReactNode, useCallback, useEffect, useMemo } from 'react';
import { type ControllerRenderProps, useForm } from 'react-hook-form';
import { safeParse } from 'valibot';
import type * as v from 'valibot';

const SeamlessToggle = ({ formFields }: { formFields: unknown }) => {
    const setSetting = useSetSeamlessHandoffSettings();
    const { data: handoffSettings } = useSeamlessHandoffSettings();
    const handleSetHandoffSettings = (enabled: boolean) => {
        setSetting.mutate({ enabled });
    };

    const validation = useMemo(() => {
        const result = safeParse(SchemaRequestCanBeAutoCreated, formFields);
        const issues = result.issues ?? [];

        return {
            valid: result.success,
            invalidReason: issues.map(i => i.message).join(', '),
        };
    }, [formFields]);

    const seamlessEnabled = useMemo(() => {
        if (validation.valid) {
            return handoffSettings?.enabled ?? false;
        }
        return false;
    }, [validation.valid, handoffSettings]);

    return (
        <FlaggedFeature flag={FlagKey.SeamlessHandoff}>
            <div className="flex items-center gap-lg px-lg py-md bg-bg-blue-primary rounded-md border-[0.5px] border-blue">
                <div className="size-10 bg-white rounded-md grow-0 shrink-0  text-bg-blue-secondary flex items-center justify-center">
                    <TextCursorInput className="size-6" />
                </div>
                <div className="flex flex-col">
                    <h4>Seamless Handoff</h4>
                    <p className="text-black-40">
                        When enabled, Console won't require users to fill out a form to create a ticket and instead will
                        use AI to create the ticket on their behalf. Currently, supported fields are limited to subject
                        and description.
                    </p>
                </div>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <div className="w-fit">
                            <Switch
                                checked={seamlessEnabled}
                                onCheckedChange={handleSetHandoffSettings}
                                disabled={!validation.valid}
                            />
                        </div>
                    </TooltipTrigger>
                    {!validation.valid && <TooltipContent>{validation.invalidReason}</TooltipContent>}
                </Tooltip>
            </div>
        </FlaggedFeature>
    );
};

const ProvisioningStatus = ({
    provider,
    cnslTicketType,
}: { provider: TicketProviderType; cnslTicketType: CnslTicketTypeType }) => {
    const { data, refetch } = useTicketProvisioning(cnslTicketType);
    const update = useSetTicketProvisioningStatus(cnslTicketType);
    const { options, selected, jiraResolution, resolutions } = data ?? {};
    const resolutionTypeOptions = resolutions?.map(r => ({ value: r.id ?? '', label: r.name ?? '' })) ?? [];
    const handleStatusUpdate = (status: string | undefined) => {
        if (status) {
            const selectedStatus = (options ?? []).find(o => o.value === status);
            update.mutate({
                provider: provider as TicketProviderType,
                status,
                statusLabel: selectedStatus?.label!,
                jiraResolution: null,
                jiraResolutionLabel: null,
            });
            refetch();
        }
    };
    const handleResolutionUpdate = (jiraResolution: string | undefined) => {
        if (jiraResolution) {
            const selectedStatus = (options ?? []).find(o => o.value === selected);
            const jiraResolutionLabel = resolutionTypeOptions?.find(r => r.value === jiraResolution)?.label;
            update.mutate({
                status: selected!,
                provider: provider as TicketProviderType,
                jiraResolution,
                statusLabel: selectedStatus?.label!,
                jiraResolutionLabel: jiraResolutionLabel!,
            });
            refetch();
        }
    };
    const selectedStatus = (options ?? []).find(o => o.value === selected);
    const shouldShowResolution =
        provider === TicketProvider.jira &&
        resolutions &&
        resolutions.length > 0 &&
        // I think only Done has resolutions, I'm not sure though.
        selectedStatus?.label === 'Done';
    const getCopy = () => {
        if (cnslTicketType === CnslTicketType.APP_ACCESS) {
            return {
                title: 'Select tracking status',
                description:
                    'Which status should Console watch for when provisioning app access through a help desk ticket?',
            };
        }
        if (cnslTicketType === CnslTicketType.IT_SUPPORT) {
            return {
                title: 'Select resolved status',
                description:
                    'Select the status that your team uses to indicate that a ticket is resolved. Console will let the requester know when their ticket has been marked as resolved',
            };
        }
        return {
            title: '',
            description: '',
        };
    };

    return (
        <div className="flex items-center gap-lg px-lg py-md bg-bg-blue-primary rounded-md border-[0.5px] border-blue">
            <div className="size-10 bg-white rounded-md grow-0 shrink-0  text-bg-blue-secondary flex items-center justify-center">
                <Ticket className="size-6" />
            </div>
            <div className="flex flex-col">
                <h4>{getCopy().title}</h4>
                <p className="text-black-40">{getCopy().description}</p>
                <div className="w-1/2 pt-sm">
                    <ComboboxPopover
                        constrainWidth
                        options={options ?? []}
                        value={selected ?? ''}
                        placeholder="Select status"
                        onChange={handleStatusUpdate}
                    />
                </div>
                {shouldShowResolution && (
                    <>
                        <br />
                        <h4>Select resolution type</h4>
                        <p className="text-black-40">
                            Which Resolution Type should Console watch for before tracking a user’s access level?
                        </p>
                        <div className="w-1/2 pt-sm">
                            <ComboboxPopover
                                constrainWidth
                                options={resolutionTypeOptions ?? []}
                                value={jiraResolution ?? ''}
                                placeholder="Select resolution"
                                onChange={handleResolutionUpdate}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const AlwaysInvisiblePopulationTypes = new Set<string>([
    RequestFieldPopulationType.HANDLEDBYCONSOLE,
    RequestFieldPopulationType.HANDLEDBYPROVIDER,
    RequestFieldPopulationType.SKIP,
]);
const AlwaysConsoleKeys = new Set(['subject', 'description']);

const isHandledByConsole = (field: { populationType: string; fieldKey: string; value?: unknown }) => {
    return (
        AlwaysConsoleKeys.has(field.fieldKey) ||
        field.populationType === RequestFieldPopulationType.HANDLEDBYCONSOLE ||
        field.value === RequestFieldPopulationType.HANDLEDBYCONSOLE
    );
};

const PROVIDER_NAMESPACE_ID = ['workspace_id'];

const isProviderNamespace = (f: string) => PROVIDER_NAMESPACE_ID.includes(f);

const OptionWrapper = ({ label, icon }: { label: string; icon: ReactNode }) => {
    return (
        <div>
            <div className="gap-sm flex items-center">
                {icon && icon}
                <span>{label}</span>
            </div>
        </div>
    );
};

const options: {
    value: string;
    label: JSX.Element | string;
}[] = [
    { value: 'SKIP', label: <OptionWrapper label="Skip Field" icon={<Space />} /> },
    { value: 'AI_GENERATED', label: <OptionWrapper label="Ask User" icon={<CircleUserRound />} /> },
    { value: 'HARDCODED', label: <OptionWrapper label="Set Value" icon={<PenLine />} /> },
    {
        value: RequestFieldPopulationType.DYNAMIC_MAPPING,
        label: <OptionWrapper label="Dynamic" icon={<ArrowRightLeft />} />,
    },
];

const getOptionsForSettings = ({ settings, required }: { settings: CnslTicketTypeSettings; required: boolean }) => {
    const excluded: string[] = [];
    if (!settings.canAskUser) {
        excluded.push('AI_GENERATED');
    }
    if (!settings.dynamicMappingOptions?.length) {
        excluded.push(RequestFieldPopulationType.DYNAMIC_MAPPING);
    }
    if (required) {
        excluded.push('SKIP');
    }
    if (settings.dynamicLabel) {
        const idx = options.findIndex(o => o.value === RequestFieldPopulationType.DYNAMIC_MAPPING);
        options[idx].label = <OptionWrapper label={settings.dynamicLabel} icon={<ArrowRightLeft />} />;
    }
    return options.filter(o => !excluded.includes(o.value));
};

/* TODO should be able to replace with <T extends FieldValues>(f: T) => ... after eslint/ts fix */
// biome-ignore lint/suspicious/noExplicitAny: see todo
type FieldProps = ControllerRenderProps<any, any>;

const CheckboxComponent = (f: FieldProps) => (
    <div className="gap-sm flex items-center">
        Checked:
        <Checkbox checked={f.value} onCheckedChange={f.onChange} />
    </div>
);
const TextComponent = (f: FieldProps) => <Input className="w-[200px]" {...f} />;
const NumberComponent = (f: FieldProps) => <Input type="number" className="w-[200px]" {...f} />;
const DateComponent = (f: FieldProps) => <Input type="date" className="w-[200px]" {...f} />;
const SelectComponent = (
    f: FieldProps,
    options: { label: string; value: unknown }[],
    fieldType: RequestFieldTypeType,
) => (
    <>
        {/* TODO remove options cast after ComboboxPopover pass */}
        <ComboboxPopover
            isMultiple={fieldType === RequestFieldType.MULTISELECT}
            disabled={f.value === RequestFieldType.UNSUPPORTED}
            TriggerWrapper={FormControl}
            defaultValue={f.value}
            options={options as { label: string; value: string }[]}
            onChange={f.onChange}
        />
        <FormMessage />
    </>
);

const fieldTypes = {
    [RequestFieldType.TEXTAREA]: TextComponent,
    [RequestFieldType.INPUT]: TextComponent,
    [RequestFieldType.DATEPICKER]: DateComponent,
    [RequestFieldType.NUMBER_INPUT]: NumberComponent,
    [RequestFieldType.SELECT]: SelectComponent,
    [RequestFieldType.MULTISELECT]: SelectComponent,
    [RequestFieldType.BOOLEAN_INPUT]: CheckboxComponent,
    // this should never render a component
    [RequestFieldType.UNSUPPORTED]: () => <div />,
};

const computeVisibility = ({
    field,
    hdField,
    settings,
}: { field: RequestProviderField; hdField: HelpDeskFieldConfig | undefined; settings: CnslTicketTypeSettings }) => {
    const key = field.fieldKey;
    const always = settings.alwaysVisibleKeys ?? [];
    if (always.includes(key)) {
        return true;
    }
    const populationType = hdField?.populationType ?? field.populationType;

    if (AlwaysInvisiblePopulationTypes.has(populationType)) {
        // skip fields are never visible
        return false;
    }
    if (populationType === 'AI_GENERATED') {
        // ask user fields are always visible
        return true;
    }
    if (hdField) {
        return hdField?.visibility === HelpDeskFieldVisibility.VISIBLE_TO_USER;
    }
    return field.visible;
};

export const HelpDeskFieldMapping = ({
    provider,
    namespace,
    cnslTicketType,
    ticketSubType,
    settings,
}: {
    provider: TicketProviderType;
    namespace: string;
    settings: CnslTicketTypeSettings;
    cnslTicketType: CnslTicketTypeType;
    ticketSubType: Nullish<string>;
}) => {
    const { data: requestFields, refetch } = useSettingRequest(provider as IntegrationId, [namespace], ticketSubType);
    const {
        data: helpDeskFields,
        refetch: refetchHelpDeskFields,
        isLoading: isLoadingHelpDeskFields,
        isFetching: isFetchingHelpDeskFields,
    } = useHelpDeskFields(provider as IntegrationId, namespace, cnslTicketType);
    const { data } = useNamespaceOptions();

    const providerFields = useMemo(() => {
        if (requestFields && !isApiError(requestFields)) {
            return requestFields.fields;
        }
        return [];
    }, [requestFields]);

    const updateMutation = useSettingRequestUpdate(provider as IntegrationId, {
        onSuccess: () => {
            refetch();
            refetchHelpDeskFields();
        },
    });

    const onSubmit = useCallback(
        async (values: v.InferInput<typeof SchemaRequestProviderFieldReq>) => {
            values.cnslTicketType = cnslTicketType;
            await updateMutation.mutateAsync(values);
        },
        [cnslTicketType, updateMutation],
    );

    const dynamicFieldTypes = new Set<string>([RequestFieldType.INPUT, RequestFieldType.TEXTAREA]);

    const fieldList = useMemo(() => {
        if (helpDeskFields) {
            const hdFields = helpDeskFields?.fields ?? [];

            return providerFields.map(field => {
                const alwaysConsole = AlwaysConsoleKeys.has(field.fieldKey);
                const hdField = hdFields.find(f => f.request_provider_field_id === field.id);
                const populationType = alwaysConsole
                    ? 'HANDLEDBYCONSOLE'
                    : (hdField?.populationType ?? field.populationType);

                const defaultVal =
                    hdField?.populationType === RequestFieldPopulationType.DYNAMIC_MAPPING
                        ? hdField?.mapping
                        : (hdField?.prefillValue ?? field.default);

                const visible = computeVisibility({ field, hdField, settings });

                return {
                    id: field.id,
                    fieldKey: field.fieldKey,
                    fieldType: field.fieldType,
                    fieldDisplay: field.fieldDisplay,
                    fieldDescription: field.fieldDescription,
                    default: defaultVal,
                    required: field.required,
                    options: field.options,
                    populationType,
                    visible,
                };
            });
        }
        return [];
    }, [providerFields, helpDeskFields, settings]);

    const form = useForm<v.InferInput<typeof SchemaRequestProviderFieldReq>>({
        resolver: valibotResolver(SchemaRequestProviderFieldReq),
    });
    const watchAllFields = form.watch();

    useEffect(() => {
        if (!isLoadingHelpDeskFields) {
            form.reset({ fields: fieldList });
        }
    }, [isLoadingHelpDeskFields, fieldList, form.reset]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: fieldList is downstream of helpDeskFields and it constantly churns. Onsubmit churns from query param
    useEffect(() => {
        if (
            !isFetchingHelpDeskFields &&
            helpDeskFields?.fields.length === 0 &&
            fieldList.length > 0 &&
            form.formState.isValid
        ) {
            onSubmit({ fields: fieldList }).catch(e => {
                console.error('Error submitting form', e);
            });
        }
    }, [helpDeskFields?.fields.length, isFetchingHelpDeskFields, fieldList.length, form.formState.isValid]);

    const namespaceName = useMemo(() => {
        const namespaceOpts = data?.[provider];
        const opts = namespaceOpts?.options ?? [];
        const match = opts.find(o => o.value === namespace);
        return match ? match.label : opts.at(0)?.label;
    }, [data, provider, namespace]);

    const namespaceDisplayName = useMemo(() => {
        const namespaceOpts = data?.[provider];
        return namespaceOpts?.displayName ?? namespace;
    }, [data, provider, namespace]);

    return (
        <div className="flex flex-col gap-lg">
            <div>
                <h3 className="text-base">
                    Configure "{namespaceName}" {namespaceDisplayName}
                </h3>
                <p className="text-body-subtle">
                    Toggle on the fields you want the user to fill out in the Slack form. For hidden fields, you can
                    determine whether or not to let Console handle it, set a pre-determined value or leave it blank.
                </p>
            </div>
            {settings.seamlessHandoff && <SeamlessToggle formFields={watchAllFields} />}
            {settings.provisioningStatus && <ProvisioningStatus provider={provider} cnslTicketType={cnslTicketType} />}
            <Form {...form}>
                <form className="flex flex-col gap-md" onSubmit={form.handleSubmit(onSubmit)}>
                    {fieldList.map((f, i) => (
                        <div key={f.id} className="flex items-center gap-md py-md border-t-[0.5px] border-grey">
                            {settings.visibilityToggle && (
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button
                                            type="button"
                                            mode="borderless"
                                            onClick={() =>
                                                form.setValue(
                                                    `fields.${i}.visible`,
                                                    !watchAllFields?.fields?.[i]?.visible,
                                                    {
                                                        shouldDirty: true,
                                                    },
                                                )
                                            }
                                            aria-label={watchAllFields?.fields?.[i]?.visible ? 'Hide' : 'Show'}
                                            disabled={[
                                                'HANDLEDBYCONSOLE',
                                                'HANDLEDBYPROVIDER',
                                                'SKIP',
                                                'AI_GENERATED',
                                            ].includes(watchAllFields?.fields?.[i]?.populationType)}
                                        >
                                            {watchAllFields?.fields?.[i]?.visible ? (
                                                <Eye />
                                            ) : (
                                                <EyeOff className="text-body-subtle" />
                                            )}
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {watchAllFields?.fields?.[i]?.visible ? 'Hide field' : 'Show field'}
                                    </TooltipContent>
                                </Tooltip>
                            )}
                            <FormField
                                control={form.control}
                                name={
                                    isProviderNamespace(f.fieldKey)
                                        ? `fields.${i}.default`
                                        : `fields.${i}.populationType`
                                }
                                render={({ field }) => {
                                    const consoleHandled = isHandledByConsole({ ...f, value: field.value });
                                    const baseOptions = getOptionsForSettings({ settings, required: f.required });
                                    let os =
                                        f.fieldType === 'UNSUPPORTED'
                                            ? options.filter(o => o.value !== 'HARDCODED')
                                            : baseOptions;

                                    os = consoleHandled
                                        ? [
                                              {
                                                  value: 'HANDLEDBYCONSOLE',
                                                  label: (
                                                      <OptionWrapper
                                                          label="Set by Console"
                                                          icon={<Sparkles className="text-bg-blue-secondary" />}
                                                      />
                                                  ),
                                              },
                                          ]
                                        : field.value === 'HANDLEDBYPROVIDER'
                                          ? [
                                                {
                                                    value: 'HANDLEDBYPROVIDER',
                                                    label: (
                                                        <OptionWrapper
                                                            label="Set by Provider"
                                                            icon={<Blocks className="text-bg-chart-emerald" />}
                                                        />
                                                    ),
                                                },
                                            ]
                                          : baseOptions;

                                    const dynamicMappingOptions = settings.dynamicMappingOptions ?? [];
                                    const isDynamic =
                                        dynamicMappingOptions.length > 0 && dynamicFieldTypes.has(f.fieldType);

                                    if (!isDynamic) {
                                        os = os.filter(o => o.value !== RequestFieldPopulationType.DYNAMIC_MAPPING);
                                    }

                                    return (
                                        <>
                                            <FormItem className="gap-lg flex items-center min-h-12 w-2/3 shrink-0">
                                                <div className="w-full overflow-hidden">
                                                    <FormLabel
                                                        className={cn(
                                                            watchAllFields?.fields?.[i]?.visible ||
                                                                !settings.visibilityToggle
                                                                ? ''
                                                                : 'text-body-subtle',
                                                        )}
                                                        required={f.required}
                                                        info={
                                                            consoleHandled
                                                                ? 'The mapping for this field is handled by Console'
                                                                : f.populationType === 'HANDLEDBYPROVIDER'
                                                                  ? 'The mapping for this field is handled by the provider'
                                                                  : f.fieldType === 'UNSUPPORTED'
                                                                    ? 'This field is unsupported'
                                                                    : ''
                                                        }
                                                    >
                                                        {f.fieldDisplay}
                                                    </FormLabel>
                                                    <FormDescription className="line-clamp-1 text-xs leading-tight">
                                                        {f.fieldDescription}
                                                    </FormDescription>
                                                </div>
                                                <div className="gap-md !mt-0 flex h-8 w-full flex-col">
                                                    <ComboboxPopover
                                                        constrainWidth
                                                        disabled={f.fieldType === 'UNSUPPORTED'}
                                                        TriggerWrapper={FormControl}
                                                        defaultValue={field.value as string}
                                                        value={
                                                            consoleHandled
                                                                ? 'HANDLEDBYCONSOLE'
                                                                : (field.value as string)
                                                        }
                                                        options={os}
                                                        onChange={x => {
                                                            if (x) {
                                                                form.setValue(
                                                                    `fields.${i}.populationType`,
                                                                    x as RequestFieldPopulationTypeType,
                                                                    {
                                                                        shouldDirty: true,
                                                                    },
                                                                );
                                                                if (AlwaysInvisiblePopulationTypes.has(x)) {
                                                                    form.setValue(`fields.${i}.visible`, false, {
                                                                        shouldDirty: true,
                                                                    });
                                                                }
                                                                if (x === 'AI_GENERATED') {
                                                                    form.setValue(`fields.${i}.visible`, true, {
                                                                        shouldDirty: true,
                                                                    });
                                                                }
                                                            }
                                                            return x;
                                                        }}
                                                    />
                                                    <FormMessage />
                                                </div>
                                            </FormItem>
                                        </>
                                    );
                                }}
                            />
                            {watchAllFields?.fields?.[i]?.populationType === 'HARDCODED' && (
                                <FormField
                                    control={form.control}
                                    name={`fields.${i}.default`}
                                    render={({ field }) => (
                                        <FormItem className="h-8 w-1/3">
                                            <FormControl>
                                                {fieldTypes[f.fieldType] &&
                                                    fieldTypes[f.fieldType](field, f.options || [], f.fieldType)}
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            )}
                            {watchAllFields?.fields?.[i]?.populationType ===
                                RequestFieldPopulationType.DYNAMIC_MAPPING && (
                                <FormField
                                    control={form.control}
                                    name={`fields.${i}.default`}
                                    render={({ field }) => (
                                        <FormItem className="h-8 w-1/3">
                                            <FormControl>
                                                {fieldTypes.SELECT(
                                                    field,
                                                    settings.dynamicMappingOptions || [],
                                                    f.fieldType,
                                                )}
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            )}
                        </div>
                    ))}
                    <div className="border-grey col-span-3 col-start-1 flex items-center justify-between border-t-[0.5px] pt-lg">
                        <Button
                            type="button"
                            mode="borderless"
                            disabled={!form.formState.isDirty || form.formState.isSubmitting}
                            onClick={e => e.stopPropagation()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="blue"
                            mode="dark"
                            type="submit"
                            disabled={!form.formState.isDirty || form.formState.isSubmitting}
                        >
                            Save
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    );
};
