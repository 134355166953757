import { Button } from '@/components/ui/button/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/form/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { useIntegrationResourcePost } from '@/hooks/mutations/integrations/useIntegrationSettingPost';
import { cn } from '@/lib/styling';
import { SETTINGS_ORGANIZATION_INTEGRATION } from '@/routes/paths';
import { IntegrationId } from 'lib/integration';
import { logger } from 'lib/log';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

export interface JiraResource {
    id: string;
    url: string;
    name: string;
    scopes: string[];
    avatarUrl: string;
}

function ResourceSelect({ provider }: { provider: IntegrationId.Jira | IntegrationId.Confluence }) {
    const [searchParams] = useSearchParams();
    const [resources, setResources] = useState<JiraResource[]>([]);
    const [selectedResource, setSelectedResource] = useState<string>('');
    const navigate = useNavigate();
    const providerName = provider.charAt(0).toUpperCase() + provider.slice(1);

    useEffect(() => {
        const encodedResources = searchParams.get('resources');
        if (encodedResources) {
            try {
                const decodedResources = JSON.parse(atob(encodedResources));
                setResources(decodedResources);
            } catch (error) {
                logger.error(error, 'Failed to decode resources:');
                toast.error(`Failed to load ${providerName} resources. Please try again.`);
                navigate(SETTINGS_ORGANIZATION_INTEGRATION);
            }
        }
    }, [navigate, searchParams.get, providerName]);

    const { mutate: setResource } = useIntegrationResourcePost(provider);

    const handleSubmit = useCallback(async () => {
        if (!selectedResource) {
            toast.error(`Please select a ${providerName} resource.`);
            return;
        }

        const resource = resources.find(r => r.id === selectedResource);
        if (!resource) return;

        try {
            setResource({ resource });
            toast.success(`${providerName} resource selected successfully.`);
            navigate(SETTINGS_ORGANIZATION_INTEGRATION);
        } catch (error) {
            console.error('Failed to save resource:', error);
            toast.error(`Failed to save ${providerName} resource. Please try again.`);
        }
    }, [selectedResource, resources, navigate, setResource, providerName]);

    if (resources.length === 0) {
        return null;
    }

    return (
        <Card className="max-w-3xl mx-auto mt-8">
            <CardHeader>
                <CardTitle>Select a {providerName} Site</CardTitle>
                <CardDescription>
                    Multiple {providerName} sites were found. Please select the one you'd like to connect.
                </CardDescription>
            </CardHeader>
            <CardContent>
                <div className="flex flex-col gap-4">
                    <RadioGroup
                        value={selectedResource}
                        onValueChange={setSelectedResource}
                        className="flex flex-col gap-3"
                    >
                        {resources.map(resource => (
                            <Label
                                key={resource.id}
                                htmlFor={resource.id}
                                className={cn(
                                    'flex items-center gap-3 p-3 border rounded-md hover:bg-accent/50 cursor-pointer transition-colors',
                                    selectedResource === resource.id ? 'border-primary bg-accent' : 'border-input',
                                )}
                            >
                                <RadioGroupItem value={resource.id} id={resource.id} className="mt-[3.5px]" />
                                <div className="flex items-center gap-3 flex-1">
                                    {resource.avatarUrl && (
                                        <img src={resource.avatarUrl} alt={resource.name} className="w-6 h-6 rounded" />
                                    )}
                                    <div className="flex flex-col">
                                        <span className="font-medium">{resource.name}</span>
                                        <span className="text-xs text-muted-foreground">{resource.url}</span>
                                    </div>
                                </div>
                            </Label>
                        ))}
                    </RadioGroup>

                    <div className="flex justify-end mt-4 space-x-3">
                        <Button variant="grey" onClick={() => navigate(SETTINGS_ORGANIZATION_INTEGRATION)}>
                            Cancel
                        </Button>
                        <Button variant="default" onClick={handleSubmit}>
                            Connect
                        </Button>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}

export function JiraResourceSelect() {
    return <ResourceSelect provider={IntegrationId.Jira} />;
}

export function ConfluenceResourceSelect() {
    return <ResourceSelect provider={IntegrationId.Confluence} />;
}
